import {defineStore} from 'pinia'
import subscribeEmailApi from '~/composables/api/subscribeEmailApi'

export const useSubscribeEmail = defineStore('storeSubscribeEmail',{
  state: () => ({
    subscribeData: {}
  }),
  actions: {
    setSubscribeEmail(response) {
      this.subscribeData = response
    },
    async subscribeEmail(request) {
      const response = await subscribeEmailApi.subscribe(request)
      this.setSubscribeEmail(response.data)
      return response.data
    }
  }
})
